import { extendTheme } from "@chakra-ui/react"

const theme = {
  breakpoints: {
    base: "0em", // 0px
    sm: "48em", // ~768px
    md: "64em", // ~1024px
    lg: "80em", // ~1280px
    xl: "96em", // ~1536px
    "2xl": "120em", // ~1920px
  },
  colors: {
    brand: {
      black: "#1D1E1B",
      blue: {
        50: "#C3F9FF",
        100: "#99ECF5",
        200: "#74D5E0",
        300: "#47B8C5",
        400: "#2F9AA6",
        500: "#0F7985",
        600: "#0B616B",
        700: "#044A52",
        800: "#033338",
        900: "#042A2E",
      },
      orange: {
        50: "#FFF8F2",
        100: "#FFE4CC",
        200: "#FFCA99",
        300: "#FFAF66",
        400: "#FF9533",
        500: "#FF7A00",
        600: "#CC6200",
        700: "#B25600",
        800: "#994900",
        900: "#803D00",
      },
      teal: {
        50: "#E8FFFB",
        100: "#DEFFF9",
        200: "#C4FFF5",
        300: "#B0FFF2",
        400: "#7DFFE9",
        500: "#5FF8DE",
        600: "#55DEC7",
        700: "#4BC4B0",
        800: "#40A392",
        900: "#368073",
      },
    },
  },
  fonts: {
    heading: `'Sohne Breit', sans-serif`,
    body: `"Sohne Mono", sans-serif`,
  },
  fontSizes: {
    "3xs": "0.45rem",
    "2xs": "0.625rem",
    xs: "0.75rem",
    sm: "0.875rem",
    md: "1rem",
    lg: "1.125rem",
    xl: "1.25rem",
    "2xl": "1.5rem",
    "3xl": "1.875rem",
    "4xl": "2.25rem",
    "5xl": "3rem",
    "6xl": "3.75rem",
    "7xl": "4.5rem",
    "8xl": "6rem",
    "9xl": "8rem",
  },

  components: {
    Button: {
      baseStyle: {
        borderRadius: "full",
        fontWeight: 700,
        px: 4,
        paddingX: 16,
        py: 4,
        fontSize: ".875rem",
        h: "auto",
        height: "auto",
      },
      variants: {
        solid: {
          bg: "brand.blue.900",
          color: "white",
          _hover: {
            bg: "brand.blue.700",
          },
        },
      },
    },
    Heading: {
      fontFamily: `'Sohne Breit', sans-serif`,
      fontWeight: "700", // Set the default font weight for headings
    },
  },
  // styles: {
  //   global: {
  //     // Import your font here
  //     "@font-face": [
  //       {
  //         fontFamily: "Sohne Breit",
  //         src: `
  //         url("/fonts/soehne-breit-dreiviertelfett.woff2") format("woff2"),
  //         url("/fonts/soehne-breit-dreiviertelfett.otf") format("opentype");
  //         `,
  //         fontWeight: 700,
  //         fontDisplay: "swap",
  //       },
  //       {
  //         fontFamily: "Sohne Breit",
  //         src: `
  //         url("/fonts/soehne-breit-extrafett.woff2") format("woff2"),
  //         url("/fonts/soehne-breit-extrafett.otf") format("opentype");
  //         `,
  //         fontWeight: 900,
  //         fontDisplay: "swap",
  //       },
  //       {
  //         fontFamily: "Sohne Mono",
  //         src: `
  //         url("/fonts/soehne-mono-buch.woff2") format("woff2"),
  //         url("/fonts/soehne-mono-buch.otf") format("opentype");
  //         `,
  //         fontWeight: 400,
  //         fontDisplay: "swap",
  //       },
  //       {
  //         fontFamily: "Sohne Mono",
  //         src: `
  //         'rl("/fonts/soehne-mono-dreiviertelfett.woff2") format("woff2"),
  //          url("/fonts/soehne-mono-dreiviertelfett.otf") format("opentype");
  //         `,
  //         fontWeight: 700,
  //         fontDisplay: "swap",
  //       },
  //     ],
  //   },
  // },
}

export default extendTheme(theme)
